import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TagMenu from "../../components/tagMenu"
import { Link } from "gatsby"

import "../../utils/normalize.css"
import "../../utils/css/screen.css"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Leia Chang" keywords={[`blog`, `gatsby`, `javascript`, `react`, `ITP`]} />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">ITP Classes</h2>
          <TagMenu />
        </header>
      )}

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>
          Blog posts and other documentation surrounding Leia's work at NYU's Interactive Telecommunications Program, 2021 - 2023.
          </p>
          <br/>

          <table>
            <thead>
              <tr>
                <th>Class</th>
                <th>Semester</th>
                <th>With</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Link to={`/tags/itp`}>All ITP</Link></td>
                <td>2021-2023</td>
                <td>~</td>
              </tr>
              <tr>
                <td><Link to={`/tags/pcomp`}>Physical Computing</Link></td>
                <td>Fall 2021</td>
                <td><a href="https://www.smoothware.com/danny/">Danny Rozin</a></td>
              </tr>
              <tr>
                <td><Link to={`/tags/hypercinema`}>Hypercinema</Link></td>
                <td>Fall 2021</td>
                <td><a href="https://mariannerpetit.com/about/">Marianne R. Petit</a></td>
              </tr>
              <tr>
                <td><Link to={`/tags/visual-language`}>Visual Language</Link></td>
                <td>Fall 2021</td>
                <td><a href="http://iamsukim.com">Su Hyun Kim</a></td>
              </tr>
              <tr>
                <td><Link to={`/tags/out-of-order-storytelling`}>Out of Order Storytelling</Link></td>
                <td>Fall 2021</td>
                <td>Mia Rovegno and Kio Stark</td>
              </tr>
              <tr>
                <td><Link to={`/tags/theme-park-eng`}>Theme Park Engineering (IDM)</Link></td>
                <td>Spring 2022</td>
                <td>Todd Bryant and Scott Fitzgerald</td>
              </tr>
              <tr>
                <td><Link to={`/tags/performing-reality`}>Performing Reality</Link></td>
                <td>Spring 2022</td>
                <td>Andrew Schneider</td>
              </tr>
              <tr>
                <td><Link to={`/tags/collective-play`}>Collective Play</Link></td>
                <td>Spring 2022</td>
                <td>Mimi Yin</td>
              </tr>
              <tr>
                <td><Link to={`/tags/haptics`}>Haptics</Link></td>
                <td>Spring 2022</td>
                <td>Kate Hartman</td>
              </tr>
              <tr>
                <td><Link to={`/tags/replasticing`}>Replasticing</Link></td>
                <td>Spring 2022</td>
                <td>Blair Simmons and Molly Ritmiller</td>
              </tr>
              <tr>
                <td><Link to={`/tags/subtraction`}>Subtraction</Link></td>
                <td>Fall 2022</td>
                <td>Ben Light</td>
              </tr>
              <tr>
                <td><Link to={`/tags/outside-the-box`}>Outside The Box</Link></td>
                <td>Fall 2022</td>
                <td>Mia Rovegno</td>
              </tr>
              <tr>
                <td><Link to={`/tags/medium-of-memory`}>Medium of Memory</Link></td>
                <td>Fall 2022</td>
                <td>Simone Salvo</td>
              </tr>
              <tr>
                <td><Link to={`/tags/itp-thesis`}>Thesis</Link></td>
                <td>Fall 2022 - Spring 2023</td>
                <td>Luisa Pereira & Simone Salvo</td>
              </tr>
              <tr>
                <td><Link to={`/tags/light-and-interactivity`}>Light & Interactivity</Link></td>
                <td>Spring 2023</td>
                <td>Tom Igoe</td>
              </tr>
              <tr>
                <td><Link to={`/tags/rwet`}>Reading and Writing Electronic Text</Link></td>
                <td>Spring 2023</td>
                <td>Allison Parrish</td>
              </tr>
              <tr>
                <td><Link to={`/tags/100-days`}>100 Days of Making</Link></td>
                <td>Spring 2023</td>
                <td>Emily Zhao</td>
              </tr>
            </tbody>
          </table>

        </div>
      </article>

    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const ItpBlog = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)

export default ItpBlog
